import { DropResult } from "@hello-pangea/dnd"
import { useRouter } from "next/router";
import { LoanListViewColumns } from "src/constants/loan"
import { Route } from "src/constants/ui";
import { useCustomizedTableValues } from 'src/contexts/customized-table-context';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { LoanTableColumn } from "src/types/loan"

export const useLoanListViewTable = () => {
    const { state, filterState, onSetSortedListColumns, onSortByColumn, onPinLoan } = useKanbanLoans();
    const router = useRouter();
    const { selectedHeaders, allHeaders, toggleHeader } = useCustomizedTableValues();
    const sortedColumns = sortColumns(state.sortedListViewColumns, LoanListViewColumns);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = [...sortedColumns]
        const [reorderedItem] = items.splice(result.source.index - 1, 1);
        items.splice(result.destination.index - 1, 0, reorderedItem);

        onSetSortedListColumns(items.map(item => item.id));
    }

    const onLoanRowClick = (loanId: string) => {
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                loanId
            }
        })
    }

    return {
        onDragEnd: handleDragEnd,
        onSortByColumn,
        toggleHeader,
        onPinLoan,
        onLoanRowClick,
        columns: sortedColumns,
        orderByColumn: state.orderByColumn,
        orderDirection: state.orderDirection,
        selectedHeaders,
        allHeaders,
        filterQuery: filterState.filterQuery
    } as const
}

const sortColumns = (ids: string[], columns: LoanTableColumn[]) => {
    return columns.sort((a, b) => {
        return ids?.indexOf(a.id) - ids?.indexOf(b.id)
    });
}

import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { Loan } from "src/types/loan";

import { LoanListViewTable } from "./loan-list-view-table";

interface LoanListViewSearchResultsProps {
    loans: Loan[];
    onSendMessageClick: (loanId: string) => void;
}

export const LoanListViewSearchResults = (props: LoanListViewSearchResultsProps) => {

    if (props.loans.length === 0) return (
        <Stack items="center" justify='center' className='flex-1' space="md">
            <Icon name="BigExclamation" width={64} height={64} className="shrink-0 text-black-20" />
            <Text size="sm">
                No results match your search
            </Text>
        </Stack>
    );

    return <LoanListViewTable
        onSendMessageClick={props.onSendMessageClick}
        loans={props.loans} />
};
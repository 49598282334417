import { useCustomizedTable } from 'components/dist/organisms/CustomizedTable';
import { CustomizedTableHeader } from 'components/dist/organisms/CustomizedTable/types';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLoanListViewTable } from 'src/components/loans/loan-list-view/loan-list-view-table.hook';
import { KeyStorage } from "src/constants/key-storage";
import { LoanListViewColumnsDefaultOrder } from 'src/constants/loan';
import { OrderDirection } from 'src/constants/ui';
import { State } from 'src/contexts/loans-kanban-context';
import { useKeyStorage } from 'src/hooks/use-key-storage';
import { LoanDisplayView, LoanSortBy } from 'src/types/loan';

export type TableColumnsValues = {
    columns: CustomizedTableHeader[];
};

const CustomizedTableContext = createContext<{
    allHeaders: CustomizedTableHeader[];
    selectedHeaders: CustomizedTableHeader[];
    toggleHeader: (header: CustomizedTableHeader) => void;
    onReset: () => void;
}>({
    allHeaders: [],
    selectedHeaders: [],
    toggleHeader: () => { },
    onReset: () => { },
});

export const CustomizedTableProvider = (props: { children: ReactNode }) => {
    const { columns } = useLoanListViewTable();

    const {
        setValue: saveRemoteSettings,
        value,
        isLoading,
    } = useKeyStorage<TableColumnsValues>(KeyStorage.TableColumns, {
        columns: [],
    });
    const { value: kanbanSettings, setValue: saveKanbanSettings } =
        useKeyStorage<
            Pick<
                State,
                | 'displayView'
                | 'sortBy'
                | 'sortedListViewColumns'
                | 'orderDirection'
                | 'orderByColumn'
            >
        >(KeyStorage.KanbanSetting, {
            displayView: LoanDisplayView.grid,
            sortBy: LoanSortBy.newest,
            orderDirection: OrderDirection.Asc,
            orderByColumn: null,
            columnsOrder: LoanListViewColumnsDefaultOrder,
        });
    const { allHeaders, selectedHeaders, setSelectedHeaders, toggleHeader } =
        useCustomizedTable({
            columns,
        });

    useEffect(() => {
        if (!isLoading && value.columns.length > 0) {
            setSelectedHeaders(value.columns.filter((header) => header));
        }
    }, [value.columns.length, isLoading, setSelectedHeaders]);

    const saveHeader = useCallback((header: CustomizedTableHeader) => {
        const updatedColumns = toggleHeader(header);
        saveRemoteSettings({ columns: updatedColumns });

    }, [toggleHeader, saveRemoteSettings]);

    const onReset = useCallback(() => {
        const orderedHeaders = LoanListViewColumnsDefaultOrder.map((id) => columns.find((header) => header.id === id));
        const updatedHeaders = setSelectedHeaders(orderedHeaders.slice(0, orderedHeaders.length));
        saveRemoteSettings({ columns: updatedHeaders });
        saveKanbanSettings({
            ...kanbanSettings,
            sortedListViewColumns: LoanListViewColumnsDefaultOrder,
        });
    }, [columns, saveRemoteSettings, setSelectedHeaders, saveKanbanSettings, kanbanSettings]);

    const memoizedValue = useMemo(() => {
        return {
            allHeaders: allHeaders.filter((header) => header),
            selectedHeaders: selectedHeaders.filter((header) => header),
            onReset,
            toggleHeader: saveHeader,
        };
    }, [allHeaders, selectedHeaders, onReset, saveHeader]);

    return (
        <CustomizedTableContext.Provider
            value={memoizedValue}
        >
            {props.children}
        </CustomizedTableContext.Provider>
    );
};

export const useCustomizedTableValues = () => {
    return useContext(CustomizedTableContext);
};

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import { useState } from "react"
import { LoanPhaseDto } from "src/backend"
import { JestTestId } from "src/constants/tests"
import { NavArrowDownIcon } from "src/icons/nav-arrow-down-icon"
import { Loan } from "src/types/loan"

import { LoanListViewStyles } from "./loan-list-view.styles"
import { LoanListViewTable } from "./loan-list-view-table"

interface LoanListViewPhaseProps {
    phase: LoanPhaseDto;
    loans: Loan[];
    onSendMessageClick: (loanId: string) => void;
}

export const LoanListViewPhase = (props: LoanListViewPhaseProps) => {
    const [open, setOpen] = useState(true);

    return <Card key={props.phase.id}>
        <LoanListViewStyles.CardHeader
            title={<>
                <LoanListViewStyles.Chip
                    size="small"
                    label={props.loans?.length ?? 0} />{props.phase.name}</>}
            action={<IconButton
                data-testid={JestTestId.LoanListViewPhaseCloseIconButton}
                onClick={() => setOpen(prevState => !prevState)}>
                <NavArrowDownIcon width={24} height={24} style={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                }} />
            </IconButton>} />
        {open && <LoanListViewTable
            onSendMessageClick={props.onSendMessageClick}
            loans={props.loans} />}
    </Card>
}
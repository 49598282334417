import React, { useState } from 'react';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { useUser } from 'src/hooks/use-user';

import { LoanKanbanPhase } from '../loan-kanban-phase/loan-kanban-phase';
import { LoansKanbanFilters } from '../loans/loans-kanban-filters';
import { LoanKanbanSearchResults } from './loan-kanban-search-results';
import { LoansKanbanStyles } from './loans-kanban.styles';
import { LoansKanbanPhaseHeader } from './loans-kanban-phase-header';

const EMPTY_ARRAY = [];


const LoansKanban = () => {
    const [focusedPhaseId, setFocusedPhaseId] = useState<string>(null);
    const { isLender, company } = useUser();
    const { state, filterState, fuzzySearchLoans, onPinLoan, onSendMessageClick } = useKanbanLoans();

    const numberOfColumns = state.statuses.length;

    const isHeaderVisible = isLender && !filterState.filterQuery;

    const isSearching = filterState.filterQuery !== '';

    return (<LoansKanbanStyles.Container
        maxWidth={false}
        className='loans-kanban-container pt-4 sm:pt-8 h-full'
        data-is-focused={!!focusedPhaseId}
        data-four-cols={numberOfColumns >= 4}>
        {isLender && <div className='sm:hidden pb-4'>
            <LoansKanbanFilters lenderId={company.id} />
        </div>}
        {isHeaderVisible && <div
            className={`bg-transparent sticky top-0 z-4 flex ${!!focusedPhaseId ? 'gap-0' : 'gap-4'}`}>
            {state.statuses.map((loanPhase) => (<LoansKanbanPhaseHeader
                focusMode={!!focusedPhaseId}
                totalLoans={state.loans?.[loanPhase.id]?.length ?? 0}
                key={loanPhase.id}
                loanPhase={loanPhase}
                isFocused={!!focusedPhaseId && loanPhase.id === focusedPhaseId}
                onFocus={setFocusedPhaseId}
            />
            ))}
        </div>}
        {isSearching && <LoanKanbanSearchResults
            loans={fuzzySearchLoans}
            query={filterState.filterQuery}
            onPinLoan={onPinLoan}
            onSendMessageClick={onSendMessageClick}
        />}
        {!isSearching && <div
            className={`flex relative max-h-full ${!!focusedPhaseId ? 'gap-0' : 'gap-4'} flex-1`} >
            {state.statuses.map((loanPhase) => (
                <LoanKanbanPhase
                    onSendMessageClick={onSendMessageClick}
                    filterQuery={filterState.filterQuery}
                    isLoading={false}
                    className={focusedPhaseId && (loanPhase.id === focusedPhaseId ? 'is-focused' : 'is-not-focused')}
                    isDragEnabled={(!focusedPhaseId && isLender)}
                    onPinLoan={onPinLoan}

                    loanPhase={loanPhase}
                    loans={state.loans?.[loanPhase.id] ?? EMPTY_ARRAY}
                    key={loanPhase.id}
                />
            ))}
        </div>}
    </LoansKanbanStyles.Container>
    );
};

export default LoansKanban;

import Button from 'components/dist/atoms/Button';
import HighlightText from "components/dist/atoms/HighlightText";
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import LoanCard from 'components/dist/organisms/LoanCard';
import React from "react";
import { JestTestId } from "src/constants/tests";
import { InfoEmptyIcon } from 'src/icons/info-empty-icon';
import { Loan, LoanTableColumn } from "src/types/loan";

import { UnreadCompanyLoanMessagesCount } from "../chat/unread-company-loan-messages-count.component";
import { LoanActionsMenu } from "../loan-actions-menu";
import { LoanReviewIcon } from "../loan-review-icon";
import { LoanOverviewCardStyles } from "../tabs/overview/v2/loan-overview-card/loan-overview-card.styles";
import { LoanListViewTableStyles } from "./loan-list-view-table.styles";
import { useLoanListViewTableRow } from "./loan-list-view-table-row.hook";

interface LoanListViewTableRowProps {
  loan: Loan;
  columns: LoanTableColumn[];
  isPinned: boolean;
  highlightText?: string;
  onPinLoan: (loanId: string) => void;
  onSendMessageClick: (loanId: string) => void;
}
export const LoanListViewTableRow = (props: LoanListViewTableRowProps) => {
  const {
    columns,
    loanInfoColumnsWithValues,
  } = useLoanListViewTableRow({
    loan: props.loan,
    columns: props.columns
  });


  const handlePinClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onPinLoan(props.loan.id)
  }

  return <>
    <LoanListViewTableStyles.TableCell
      style={{ textOverflow: 'unset' }}
      data-align-center="true">
      <Button
        variant="ghost"
        size="sm"
        data-testid={JestTestId.LoanListViewTableRowPinIconButton}
        className={`pin-button ${props.isPinned ? 'is-pinned' : 'is-unpinned'} px-1`}
        onClick={handlePinClick}>
        <Icon name={props.loan.pinned ? "RemovePin" : "Pin"} strokeWidth={1.5} width={20} height={20} />
      </Button>
      {loanInfoColumnsWithValues.length > 0
        ? <LoanOverviewCardStyles.LightTooltip
          placement={'bottom-start'}
          title={
            <LoanCard>
              {loanInfoColumnsWithValues.map((column) => (
                <LoanCard.Item key={column.id}
                  className={column.id === 'loanProgress' ? '[&>div]:flex-col' : ''}>
                  <LoanCard.Title>{column.label}</LoanCard.Title>
                  <LoanCard.Value className={`truncate ${column.id === 'loanProgress' ? 'mt-2 mb-2' : ''}`}>
                    {column.formattedValue}
                  </LoanCard.Value>
                </LoanCard.Item>
              ))}
            </LoanCard>
          }
        >
          <Button
            variant="ghost"
            size="sm"
            className='text-blue-100'
            data-testid={JestTestId.LoanListTableRowInfoButton}>
            <InfoEmptyIcon width={20} height={20} />
          </Button>
        </LoanOverviewCardStyles.LightTooltip>
        : <Button
          className='opacity-0 cursor-default'
          data-testid={JestTestId.LoanListTableRowInfoButton}>
          <InfoEmptyIcon width={20} height={20} />
        </Button>}
      <div className="pl-2 inline-block">
        <LoanReviewIcon status={props.loan.reviewStatus} />
      </div>
    </LoanListViewTableStyles.TableCell>
    {columns.map((column) => (
      <LoanListViewTableStyles.TableCell
        align={column.align || 'left'}
        key={column.id}>
        {(column.formattedValue && !column.isComponent)
          ? <HighlightText
            text={column.formattedValue}
            highlightText={props.highlightText}
          />
          : column.formattedValue}
      </LoanListViewTableStyles.TableCell>
    ))}
    <LoanListViewTableStyles.TableCell align="right">
      <Stack row space="sm" justify="end">
        <UnreadCompanyLoanMessagesCount loanId={props.loan.id} companyId={props.loan.lender.id} />
        <LoanActionsMenu
          formElements={[]}
          onCopy={() => { }}
          onSendMessageClick={props.onSendMessageClick}
          loan={props.loan} />
      </Stack>
    </LoanListViewTableStyles.TableCell>
  </>
};
